import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { Classes } from 'jss';
import { TranslateService } from '@ngx-translate/core';

import { BasePlatformService } from '@common/services/base-platform.service';

import { ETime } from '@common/enums/time.enum';

import { ETimerAppearanceSeparator } from './enum/timer.enum';
import { defaultTimerLabels } from './constants/timer.const';
import { ETimerType, type RemainingTimeType, TimerData } from './types/timer.type';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'sp-timer',
    templateUrl: './sp-timer.component.html',
    styleUrls: ['./sp-timer.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpTimerComponent implements OnChanges, OnDestroy {
    @Input() public classes!: Classes;
    @Input() public timerData!: TimerData;
    @Input() public isDeviceMobile!: boolean;
    @Input() public timerId!: string;

    public timeInterval: any;
    public remainingTime: RemainingTimeType;
    public isExpiredTime: boolean;
    public ETime = ETime;
    public ETimerAppearanceSeparator = ETimerAppearanceSeparator;

    public TIMER_UNTIL_DATE: string;

    public currentUntilDate: any;

    constructor(
        private readonly platformService: BasePlatformService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly translate: TranslateService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        this.TIMER_UNTIL_DATE = 'sp-timer-until-date_' + this.timerId;
        const { timerData } = changes;

        if (!timerData?.currentValue) {
            return;
        }

        if (this.timerData?.timerType === ETimerType.dynamic && timerData.previousValue) {
            this.setDynamicDateToLocalStorage(this.timerData?.remainingTime);
        }

        if (this.platformService.isPlatformServer()) {
            this.timer();
            return;
        }

        if ((this.timerData?.timerType === ETimerType.fixed && (new Date() <= new Date(this.timerData.untilDate))) ||
            (this.timerData?.timerType === ETimerType.dynamic && (new Date() <= new Date(this.getDynamicUntilDate(this.timerData?.remainingTime))))) {
            this.isExpiredTime = false;
            this.stopTimer();
            this.startTimer();
            return;
        }

        this.isExpiredTime = true;

        this.stopTimer();
    }

    public hasColumn(column): boolean {
        return this.timerData.columns ? this.timerData.columns.includes(column) : true;
    }

    public label(timeUnit: ETime): string {
        if (typeof this.timerData.customLabels === 'object' && this.timerData.customLabels !== null) {
            return this.timerData.customLabels[timeUnit]
                ? this.timerData.customLabels[timeUnit]
                : this.translate.instant(defaultTimerLabels[timeUnit]);
        }

        return this.translate.instant(defaultTimerLabels[timeUnit]);
    }

    private startTimer(): void {
        this.timer();
        this.timeInterval = setInterval(() => {
            this.timer();
        }, 1000);
    }

    private stopTimer(): void {
        clearInterval(this.timeInterval);
    }

    private getDynamicUntilDate(remainingTime) {
        let resultDate = new Date();

        resultDate.setDate(resultDate.getDate() + remainingTime.days);
        resultDate.setHours(resultDate.getHours() + remainingTime.hours);
        resultDate.setMinutes(resultDate.getMinutes() + remainingTime.minutes);
        resultDate.setSeconds(resultDate.getSeconds() + remainingTime.seconds);

        return resultDate;
    }

    private setDynamicDateToLocalStorage(remainingTime) {
        let resultDate = this.getDynamicUntilDate(remainingTime);

        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(this.TIMER_UNTIL_DATE, JSON.stringify(resultDate));
        }
    }

    private timer(): void {
        this.isExpiredTime = false;
        const fromDate = new Date();
        let toDate = new Date();

        if (this.timerData?.timerType === ETimerType.dynamic) {
            if (isPlatformBrowser(this.platformId)) {
                const hasDynamicDate = JSON.parse(localStorage.getItem(this.TIMER_UNTIL_DATE)) as string;

                if (hasDynamicDate) {
                    toDate = new Date(hasDynamicDate);
                } else {
                    this.setDynamicDateToLocalStorage(this.timerData?.remainingTime);
                }
            }
        } else {
            toDate = new Date(this.timerData.untilDate);
        }

        if (fromDate > toDate) {
            if (this.timerData?.autorestart && this.timerData?.timerType === ETimerType.dynamic) {
                this.setDynamicDateToLocalStorage(this.timerData?.remainingTime);
            } else {
                this.isExpiredTime = true;
            }
        }

        const delta = Math.abs((fromDate.getTime() - toDate.getTime()) / 1000);

        let seconds: string;
        let minutes: string;
        let hours: string;
        let days: string;

        if (this.hasColumn(ETime.days)) {
            days = Math.floor(delta / 86400).toString();
            hours = Math.floor((delta / 3600) % 24).toString();
            minutes = Math.floor((delta / 60) % 60).toString();
            seconds = Math.floor(delta % 60).toString();
        } else if (!this.hasColumn(ETime.days) && this.hasColumn(ETime.hours)) {
            hours = Math.floor(delta / 3600).toString();
            minutes = Math.floor((delta / 60) % 60).toString();
            seconds = Math.floor(delta % 60).toString();
        } else if (!this.hasColumn(ETime.days) && !this.hasColumn(ETime.hours) && this.hasColumn(ETime.minutes)) {
            minutes = Math.floor(delta / 60).toString();
            seconds = Math.floor(delta % 60).toString();
        } else if (!this.hasColumn(ETime.days) && !this.hasColumn(ETime.hours) && !this.hasColumn(ETime.minutes)) {
            seconds = Math.floor(delta).toString();
        }

        this.remainingTime = {
            days,
            hours,
            minutes,
            seconds,
        };

        this.changeDetectorRef.detectChanges();
    }

    public ngOnDestroy(): void {
        this.stopTimer();
    }
}
